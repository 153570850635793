import { data } from "jquery";
import { getMetadata } from "./Request";
import {backgrounds, groupingMap, interiorSet, materialGroup, modelSet, RenderEngine, visibilitySet} from "./renderEngine";
import {win32} from "path";
import EventEmitter from "events";

export class MainController{

    private engine:RenderEngine;

    public static event:EventEmitter = new EventEmitter();

    private loadingModel = false;
    public doneLoadingModel(){
        this.getmetaData().then(()=>{
            this.loadingModel = false;
            if(this.queryQueue.length > 0){
                let q = this.queryQueue[0];
                this.queryQueue.shift();
                this.setMeshRecusive(q);
            }
        })
    }
    private queryQueue:string[] = [];

    public static fullscreen:boolean = false;

    private currentQuery = "";

    constructor(engine:RenderEngine){
        this.engine = engine;
		
		//const assets = this.controller3D.engine.assetProvider.getAssets();
		
        window.addEventListener("message", (event:any) => {

            //console.log(event.data)


            if(event.data === "screenshot"){
                engine.getScreenShot().then((image)=>{
                    event.source.postMessage(image, "https://5179281-sb1.app.netsuite.com/");
                })
            }
            else if(event.data === "fullscreen"){
                engine.resize();
                MainController.fullscreen = true;
            }
            else if(event.data === "exit-fullscreen"){
                engine.resize();
                MainController.fullscreen = false;
            } else if ((typeof event.data === 'string' || event.data instanceof String) && event.data.startsWith("ctemp=")) {
                let split_data= event.data.split("=");
                if (split_data.length > 1) {
                    this.engine.setLightTemperature(parseInt(split_data[1].replace("K", "")));
                    if (this.isLightEnabled) {
                        this.updateLight();
                    }
                }
            } else if ((typeof event.data === 'string' || event.data instanceof String) && event.data.startsWith("lmult=")) {
                let split_data= event.data.split("=");
                if (split_data.length > 1) {
                    this.engine.setURLParaIntensityMult(parseFloat(split_data[1]));
                    if (this.isLightEnabled) {
                        this.updateLight();
                    }
                }
            }
            else{
                let query = this.specialGlasMesh(event.data);
                console.log(`query,${query}`);
                if(!query || typeof query != 'string'){
                    return;
                }
                this.currentQuery = query;
                
                let urlParams = new URLSearchParams(query);
                
                if(!this.loadingModel){
                    this.setMeshRecusive(query)
                }
                else{
                    this.queryQueue.push(query);
                    console.log("queryQueue", this.queryQueue)
                }

                urlParams.forEach((v,k)=>{
                    if(k === "HTL"){
                        this.HTL = parseFloat(v)
                        this.engine.morph(k, parseFloat(v))
                    }
                    this.saveParameter(k,v);
                })

                this.filterMetalAccentTrimless();
                this.applySavedParameters(engine);
            }
        },false)
    }

    public putQueryToURL():string{
        let url = window.location.href;
        if(this.currentQuery === ""){
            return url;
        }
        if(this.currentQuery.includes("Fixture")){
            url = window.location.protocol + "//" + window.location.hostname + window.location.pathname + "?" + this.currentQuery + "&toolbar=true";  
        }
        else{
            url = url + "&" + this.currentQuery;
        }
        url = url.split(" ").join("%20");
        url = url.split('"').join("%22");
        return url;
    }

    private filterMetalAccentTrimless() {
        if((this.savedParameters.get('CANOPY') as string)?.length > 0) {
            let canopy= this.savedParameters.get('CANOPY') as string;

            if (canopy === "TRIMLESS") {
                let delete_metal_finish= false;
                if ((this.savedParameters.get('METAL_FINISH') as string)?.length > 0) {
                    let metal_finish= this.savedParameters.get('METAL_FINISH') as string;

                    let metal_accent_base_finishes= ["MATTE_WHITE_PC", "FLAT_BLACK_PC", "PORCELAIN_WHITE_PC", "RED_SAND_PC", "BURNT_SIENNA_PC", "FOREST_GREEN_PC"];

                    delete_metal_finish= !metal_accent_base_finishes.includes(metal_finish);
                } else {
                    delete_metal_finish= true;
                }

                if (delete_metal_finish) {
                    this.savedParameters.delete("METAL_FINISH_ACCENT");
                    this.savedParameters.delete("ACCENT_FINISH");
                }
            }
        }
    }

    private latestFixture = "";

    public setLatestFixtureToCurrent(){
        this.latestFixture = this.currentFixture;
    }

    public setMeshRecusive(query?:string){
        this.setMesh(this.engine, query).then(()=>{
           
            this.loadingModel = false;
            if(this.queryQueue.length > 0){
                let q = this.queryQueue[0];
                this.queryQueue.shift();
                console.log("q = " + q + " queryQueue", this.queryQueue)
                this.setMeshRecusive(q);
            }

            const sameFixutre = this.latestFixture === this.currentFixture;
            
            if (this.isEnvironmentEnabled && (!sameFixutre)) {
                console.log("isSameFixture", sameFixutre)
                this.updateRoom();
                this.setLatestFixtureToCurrent()
            }
            else{
                this.setLatestFixtureToCurrent()
            }
        })
    }

    //private previousQuery = "";

    private option={
        lamp:"",
        tubing:"",
        socket:"",
        dome:"",
        innerdome:"",
        glass:"",
        quality:""
    }

    public perforated = 
    [
        'ADA Aperture - Alabaster 10"',         //prev: ADA Alabaster Sconce 10",
        'Slim Aperture - Alabaster 14"',        //prev: ADA Alabaster Sconce 14" -> Slim Alabaster Sconce 14",
        'ADA Aperture - Slumped Glass 10"',     //prev: ADA Aperture Sconce 10",
        'Slim Aperture - Slumped Glass 16"',    //prev: ADA Aperture Sconce 16" -> Slim Aperture Sconce 16"
        'Aperture 4 20"',
        'Aperture 4 30"',
        'Aperture Bare Pendant',
        'Aperture Double',
        'Aperture Sconce',
        'Aperture Sconce with Handle',
        'Aperture Table Lamp',
        'Grand Aperture 4', 
        'ADA Aperture - Cast Glass 10"'
    ]

	public new_perforated= ['Dome Table Lamp', 'Double Dome Table Lamp'];

    public setMesh(engine:any, q?:string):Promise<any>{
        this.loadingModel = true;
        return new Promise<void>((res,rej)=>{
            let query;
            if(!q){
                // console.log("need new query")
                query = this.specialGlasMesh(window.location.search);
                if(!query || typeof query != 'string'){
                    return res();
                }
                //this.previousQuery = query.replace("%20"," ");
            }
            else{
                // console.log("no need new query")
                query = q;
            }
            let urlParams = new URLSearchParams(query);
            let token = urlParams.get('Fixture');
            if((urlParams.get('Fixture') as string)?.length > 0) {
                this.modalName = urlParams.get('Fixture') as string;

                if((urlParams.get('CANOPY') as string)?.length > 0) {
                    let canopy: string= urlParams.get('CANOPY') as string;
    
                    if (canopy === "TRIMLESS") {
                        this.modalName+= " Trimless";
                    }
                }

                // if((urlParams.get('MOUNTING_TYPE') as string)?.length > 0) {
                //     let mounting_type: string= urlParams.get('MOUNTING_TYPE') as string;
    
                //     if (mounting_type === "SINGLE_GANG_J_BOX" && !this.modalName.includes("SINGLE_GANG_J_BOX ")) {
                //         if (this.modalName.startsWith("Gesso")) {
                //             this.modalName+= "-SINGLE_GANG_J_BOX";
                //         }
                //     }
                // }

                
                //TRIMLESS variances
                // let mounting_type_suffix= "";
                // let has_mounting_type_variance: boolean= this.modalName.startsWith("Trimless Stem") || this.modalName.startsWith("Trimless Flush");

                let shade_suffix= " NO_SHADE";
                let has_shade_variance: boolean= this.modalName.startsWith("Trimless Flush");

                // if((urlParams.get('MOUNTING_SURFACE') as string)?.length > 0) {
                //     let mounting_surface: string= urlParams.get('MOUNTING_SURFACE') as string;
    
                //     mounting_type_suffix= mounting_surface=="DRYWALL"? " Drywall" : " Other";
                // }

                if((urlParams.get('SHADE') as string)?.length > 0) {
                    let shade: string= urlParams.get('SHADE') as string;
    
                    shade_suffix= " " + shade;
                }

                // if (has_mounting_type_variance) {
                //     this.modalName+= mounting_type_suffix;
                // }

                if (has_shade_variance) {
                    this.modalName+= shade_suffix;
                }

                token= this.modalName;
            }

            if(!token){
                return res();
            }

            let t = token;
            if(token.split("Perforated ").length >= 2){
                console.log("perforated", token.split("Perforated ")[1])
                t = token.split("Perforated ")[1];
            }
            else if(token.split(" Perforated").length >= 2){
                console.log("perforated", token.split(" Perforated")[0])
                t = token.split(" Perforated")[0];
            }

            

            let id = "";

            modelSet.forEach((v,k)=>{
                if(v.split("/")[0] === t){
                    id = k;
                }
            })

            if(id === ""){
                modelSet.forEach((v,k)=>{
                    for(let i = 0; i < this.specialMeshesDefault.length; i++){
                        if(v.split("/")[0] === t + this.specialMeshesDefault[i]){
                            id = k;
                            break
                        }
                    }
                })
            }

            if(id === ""){
                modelSet.forEach((v,k)=>{
                    if(v.split("-")[0] === t){
                        id = k;
                    }
                })
            }

            // console.log("ID", id)
            // console.log("visi", visibilitySet);

            if(id != ""){
                engine.changeFixture(visibilitySet.find(e => e.name === "Fixture").id, id).then(()=>{
                    if (!this.isEnvironmentEnabled) {
                        engine.changeRenderSetting(modelSet.get(id));
                    }
                    this._currentModelSet= modelSet.get(id);
                    if(this.HTL > -1){
                        engine.morph("HTL", this.HTL)
                    }
                    console.log("change fixture to", t, modelSet.get(id));
                    this.currentFixture = modelSet.get(id);
                    this.updateLight();
                    return res();
                });
            }
            else {
                return res();
            }
        })
    }

    private currentFixture = "";
	
	private getIsPerforated() {
		//let perforated= query.indexOf("Perforated") >= 0;
        let perforated= false;
        this.savedParameters.forEach((v, k) => {
            perforated= perforated || ( v ? (v.indexOf("Perforated")>=0) : false );
        });
		
		//let urlParams = new URLSearchParams(query);
        let urlParams= this.savedParameters;
		if(this.perforated.indexOf(urlParams.get('Fixture') as string) >= 0 ){
            perforated = true;
        }
		
		//check if perforated through dome_material parameter (new)
		let dome_material= urlParams.get('DOME_MATERIAL')?urlParams.get('DOME_MATERIAL'):'METAL';
		let dome_perforated= dome_material === 'PERFORATED';
		
		let modelName = urlParams.get('Fixture') as string;
		//let model_meta = this.metadata.fixture_data.find(e => e.name === modelName);
		
		if(this.new_perforated.indexOf(modelName) >= 0) {
			//console.log("allow perfo______");
			//console.log("dome perfo: " + dome_perforated);
			perforated= perforated || dome_perforated;
		}
		
		return perforated;
	}


    public setOption(engine){
        let query = this.specialGlasMesh(window.location.search);
        if(!query || typeof query != 'string'){
            return;
        }
        //this.previousQuery = query.replace("%20"," ");
        let urlParams = new URLSearchParams(query);
        

        // console.log("fixture", urlParams.get('Fixture'))
        
        urlParams.forEach((v,k)=>{
            if(k === "HTL"){
                this.engine.morph(k, parseFloat(v))
            } else if (k === "CTEMP") {
                this.engine.setLightTemperature(parseInt(v.replace("K", "")));
            } else if (k === "LMULT") {
                this.engine.setURLParaIntensityMult(parseFloat(v));
            }
            this.saveParameter(k,v);
        });

        this.filterMetalAccentTrimless();
        this.applySavedParameters(engine);
        
    }

    private savedParameters = new Map<string, string>();
    private saveParameter(key : string, value : string) {
        this.savedParameters.set(key, value);
    }
    private applySavedParameters(engine) {
        //console.log(this.savedParameters);
        let perforated= this.getIsPerforated();
        this.savedParameters.forEach((v, k) => {
            this.applyMaterials(k, v, engine, perforated);
        });
    }

    public applyMaterials(s:string, token:string, engine:RenderEngine, perforated:boolean):any{
        //console.log("qu " + s + ":" + token)
        if (token) {
            materialGroup.forEach((mat_group)=>{
                let id = "";
                if(perforated){
                    if(mat_group.name.split("/")[0] === s){
                        let o = mat_group.mats.find(e => e.name === (token+"/P"));
                        if(o){
                            id = o.id;
                            // console.log("perforated ", id);
                        }
                        if(id != ""){
                            engine.configurator.setMaterialGroup(mat_group.id, id);
                        }
                    }
                }
                if(id === ""){
                    if(mat_group.name.split("/")[0] === s){
                        let found_mat = mat_group.mats.find(e => (e.name.includes("/P")?e.name:e.name.split("/")[0]) === token);
                        if(!found_mat){ 
                            found_mat = mat_group.mats.find(e => (e.name.startsWith("DEFAULT")));
                        }
                        if(found_mat){
                            if (this.isLightEnabled) {
                                let mat_lit= mat_group.mats.find(e => e.name === found_mat.name+"_LIT");
                                if (mat_lit) {
                                    found_mat= mat_lit;
                                }
                            }

                            // console.log("find", o)
                            id = found_mat.id;
                            if(id != ""){
                                //console.log("idw: ", found_mat)
                                engine.configurator.setMaterialGroup(mat_group.id, id);
                            }
                        }
                    }
                    else if(mat_group.name.split("/")[0] === s + "_ACCENT" ){
                        //const urlParams = new URLSearchParams(query);
                        let mat = this.savedParameters.get('ACCENT_FINISH')?this.savedParameters.get('ACCENT_FINISH'):token;
                        let o = mat_group.mats.find(e => (e.name.includes("/P")?e.name:e.name.split("/")[0]) === mat);
                        if(o){
                            // console.log("find", o)
                            id = o.id;
                            if(id != ""){
                                // console.log("id: ", w)
                                engine.configurator.setMaterialGroup(mat_group.id, id);
                            }
                        }
                    }
                }
            });
        }
    }

    private HTL = -1;

    //SPACING
    private specialMesh(query:string, special:string):string{
        let q = query;
        const urlParams = new URLSearchParams(q);
        const mount = urlParams.get(special);
        if(mount){
            console.log(special + "=" + mount)
            //remove the whole if when there are more spacing option available
            if(query === "SPACING"){
                let fix = q.split('&').find(e => e.includes("Fixture"));
                if(fix){
                    q = q.replace(fix, fix+"-SEMI_TIGHT");
                }

                return q;
            }
            let fix = q.split('&').find(e => e.includes("Fixture"));
            if(fix){
                let new_fix= fix+"-"+mount;
                let new_q = q.replace(fix, new_fix);

                let new_fix_name= urlParams.get("Fixture")+"-"+mount;

                //only replace the model if the replaced version exists
                let found_model= null;
                modelSet.forEach((v,k)=>{
                    // console.log(v, new_fix_name)
                    if(v === new_fix_name){
                        found_model= k;
                    }
                });
                if (found_model != null) {
                    console.log("found replacement model: ", fix, new_fix);
                    q=new_q;
                }
            }
        }
        return q;
    }

    private specialMeshes = ["MOUNTING_TYPE", "CERAMIC_FINISH", "SPACING"]
    private specialMeshesDefault = ["-SINGLE_GANG_J_BOX","-SEMI_TIGHT"]

    private specialGlasMesh(query:string):string | null {
        if(!query || typeof query != 'string'){
            return null;
        }
        // console.log("query", query)
        let s = query.slice();
        if(s.indexOf("Endless Dome") >= 0 || s.indexOf("Endless%20Dome") >= 0){
            s = s.replace("DOME_FINISH=FROSTED_GLASS", "DOME_FINISH=clear_to_frosted_gradient_glass");
            s = s.replace("DOME_FINISH=SMOKED_GLASS", "DOME_FINISH=FROSTED_TO_SMOKED_GRADIENT_GLASS");
        }
        else if(s.indexOf('Prospect Double Sconce 8"') >= 0|| s.indexOf('Prospect%20Double%20Sconce%2008%22') >= 0){
            let domefinish = query.split("&").find(e => e.indexOf("DOME_FINISH") >= 0)
            if(domefinish && domefinish.indexOf('GLASS') >= 0){
                // console.log("yo")
                s = s.replace('Fixture=Prospect Double Sconce 08"', 'Fixture=Prospect Double Sconce 08"_GLASS_DOME');
                s = s.replace("Fixture=Prospect%20Double%20Sconce%2008%22", "Fixture=Prospect%20Double%20Sconce%2008%22_GLASS_DOME");
            }
        }
        else if(s.indexOf('Prospect Double Sconce 14"') >= 0|| s.indexOf('Prospect%20Double%20Sconce%2014%22') >= 0){
            let domefinish = query.split("&").find(e => e.indexOf("DOME_FINISH") >= 0)
            if(domefinish && domefinish.indexOf('GLASS') >= 0){
                // console.log("yolo")
                s = s.replace('Prospect Double Sconce 14"', 'Prospect Double Sconce 14"_GLASS_DOME');
                s = s.replace('Fixture=Prospect%20Double%20Sconce%2014%22', 'Fixture=Prospect%20Double%20Sconce%2014%22_GLASS_DOME');
            }
        }
        else if(s.indexOf('Tangent 3 Torchiere') >= 0|| s.indexOf('Tangent%203%20Torchiere') >= 0){
            let domefinish = query.split("&").find(e => e.indexOf("DOME_FINISH") >= 0)
            if(domefinish && domefinish.indexOf('GLASS') >= 0){
                console.log("yolo")
                s = s.replace('Fixture=Tangent 3 Torchiere', 'Fixture=Tangent 3 Torchiere_GLASS_DOME');
                s = s.replace('Fixture=Tangent%203%20Torchiere', 'Fixture=Tangent%203%20Torchiere_GLASS_DOME');
            }
        }

        if(s.indexOf("LEATHER_FINISH=BLACK_LEATHER") >= 0){
            s = s += "&SEAM=Seam 01 Black"
        }
        else if(s.indexOf("LEATHER_FINISH=TAN_LEATHER") >= 0){
            s = s += "&SEAM=Seam 01 Brown"
        }

        this.specialMeshes.forEach((e)=>{
            s = this.specialMesh(s, e);
        })

        // console.log("s", s)
        return s;
    }

    private specialGlasMeshes = ["Endless Dome"]

    private metadata:any;

    public async getmetaData():Promise<any>{
        return getMetadata().then((data)=>{
            this.metadata = data;

            this.engine.metaData= data;
        })
    }

    //light and environment

    private isLightEnabled:boolean= false;
    private isEnvironmentEnabled:boolean= false;
    private _modalName:string = "";
    public get modalName():string{
        return this._modalName.replace("%20"," ")
    }
    private _currentModelSet: string= "";

    public set modalName(s:string){
        console.log("modal", s)
        this._modalName = s.replace("%20"," ")
        MainController.event.emit("modalChange", {modal: this._modalName})
    }

    public onToggleLight() {
        this.isLightEnabled= !this.isLightEnabled;
        this.updateLight();
    }

    public onSetRotation(rotation: number) {
        console.log(rotation);

        this.engine.setRotation(rotation);
    }

    public onOpenStorePage() {
        let page_name= this._modalName.replace(/ /g, "-").replace(/"/g, "");
        page_name= page_name.replace("-4_HEX_J_BOX", "");
        page_name= page_name.replace("-SINGLE_GANG_J_BOX", "");
        page_name= page_name.replace("-SEMI_TIGHT", "");
        page_name= page_name.replace("-BLACK_WHITE", "");
        page_name= page_name.replace("-RINGS", "");
        page_name= page_name.replace("-FIREFLY", "");

        let page_url= "https://www.alliedmaker.com/"+page_name;
        window.open(page_url); 
    }


    private getModifiedModelName() :string {
        return this.modalName.replace(" Perforated", "").replace("Perforated ", "");
    }


    private updateLight() {
        //let urlParams = new URLSearchParams(this.previousQuery);
        let urlParams = this.savedParameters;
        let globe_material= "default";
        let material_priority= -1;
        urlParams.forEach((v,k)=>{
            if(k === "GLOBE_FINISH"  ){
                globe_material= v;
                material_priority= 5;
            } else if (k === "DOME_FINISH" && material_priority <= 1) {
                globe_material= v;
                material_priority= 1
            } else if (k === "GLASS_FINISH" && material_priority <= 4) {
                globe_material= v;
                material_priority= 4;
            } else if (k === "SHADE_FINISH" && material_priority <= 1 ) {
                globe_material= v;
                material_priority= 1;
            }
        });

        
        let lamp_type= this.getLampType();

        let model_name= this.getModifiedModelName();
		this.engine.updateLight(this.isLightEnabled, model_name, globe_material, lamp_type);


        
        // urlParams.forEach((v,k)=>{
        //     this.saveParameter(k,v);
        // });

        this.filterMetalAccentTrimless();
        this.applySavedParameters(this.engine);
        this.updateEnvironment();
    }

    private setMaterialByName(group_name:string, material_name:string) {
        materialGroup.forEach((w)=>{
            if (w.name == group_name) {
                let res_mat= w.mats.find((mat) => mat.name== material_name);
                if (res_mat != undefined) {
                    this.engine.changeMaterial(w.id, res_mat.id);
                }
            }
        });
    }
    
    public onToggleEnvironment(){
        this.isEnvironmentEnabled= !this.isEnvironmentEnabled;
        this.updateRoom();
    }

    private lerp (start:number, end:number, t:number){
        return (1-t)*start+t*end;
    }

    private getFixtureData(model:string) : any {


        let fixture_data= null

        if (this.metadata == null) return null;
        
        this.metadata["fixture_data"].forEach(fd => {
            
            if (fd.name == model) {
                //console.log("!!!!!", model, fd)
                fixture_data= fd;
            }
        });

        return fixture_data;
    }

    private getLampType() : string {
        let fixture_data= this.getFixtureData(this.modalName);
        let is_wall_lamp= fixture_data && fixture_data["room_cam"] == "Interior Wall Lamp";
        let is_wall_lamp_high= fixture_data && fixture_data["room_cam"] == "Interior Wall Lamp High";
        let is_table_lamp= fixture_data && fixture_data["room_cam"] == "Interior Table Lamp";

       // console.log("is wall lamp?", is_wall_lamp, fixture_data);
        if (is_wall_lamp) {
            return "wall_lamp";
        } else if (is_wall_lamp_high) {
            return "wall_lamp_high";
        } else if (is_table_lamp) {
            return "table_lamp";
        } else {
            return "default";
        }
    }


    private updateRoom() {
        let lamp_type: string= this.getLampType();
        

        this.engine.updateRoomMesh(this.isEnvironmentEnabled, lamp_type).then(() => {
    
            this.updateLight();


            let htl:number= -1;

            if (this.isEnvironmentEnabled) {
                let camName = this.metadata.defaultCam;
                let modal = this.metadata.fixture_data.find(e => e.name === this.modalName);
                if(modal){
                    camName = modal.room_cam;
                }
                htl= 125;
                console.log("cam name", camName)
                this.engine.resetCam(camName, true);
                this.engine.changeRenderSetting("PC Interior");
            } else {
                htl= this.HTL;
                
                if (htl === -1) {
                    //if no HTL has been set (-1): reset htl to 0.1, which is the default used when exporting the meshes
                    htl = this.metadata.defaultHTL;
                    let modal = this.metadata.fixture_data.find(e => e.name === this.modalName);
                    if(modal){
                        htl = modal.default_htl;
                    }
                }
    
                //this.engine.resetCam(undefined, true);
                this.engine.resetCam(this._currentModelSet, true);
                this.engine.changeRenderSetting(this._currentModelSet)
            }

            this.engine.morph("HTL", htl, true);
            //console.log("change to", interior_option)
        });
    }

    private updateEnvironment() {
        console.log("change env")
        let env_query= "";
        let show_contact_shadow: boolean= false;
        
        if (this.isEnvironmentEnabled) {
            if (this.isLightEnabled) {
                env_query= "BG Interior Fixture On"
                //env_query= "BG Interior";
            } else {
                env_query= "BG Interior";
                show_contact_shadow= true;
            }
        } else {
            env_query= "Lebombo";
        }

        this.setMaterialByName("SHADOW_TOGGLE", show_contact_shadow? "ShadowGradient" : "INVISIBLE");

        let id:string = backgrounds.find(e => e.name === env_query).id;
        this.engine.changeEnvironment(id);
    }
}