import * as React from "react";
import {
    backgrounds,
    materialGroup,
    modelSet,
    RenderEngine,
    renderSettings,
    visibilitySet
} from "../service/renderEngine";
import './App.css'
import {Menu} from "./Menu/Menu";
import {logFix} from "../service/fikxData";
import {MainController} from "../service/MainController";
import {Toolbar} from './Toolbar/Toolbar';

interface Props{

}

interface State{
    engine: RenderEngine,
    controller:MainController,
    materialGroup: any[],
    backgrounds: any[],
    visibilitySet: any[],
    renderSetting:any[],
    loading:boolean,
    doneLoading:boolean,
    menuOn:boolean,
    noMenu:boolean,
    toolbarHidden:boolean,
    rotationSliderHidden:boolean
}

export class App extends React.Component<Props,State> {
  constructor(props:Props){
    super(props);
    logFix();
    const engine: RenderEngine = new RenderEngine();
      const controller: MainController = new MainController(engine);
      this.state={
          engine: engine,
          controller:controller,
          materialGroup: [],
          backgrounds: [],
          visibilitySet:[],
          renderSetting:[],
          loading:true,
          doneLoading:false,
          menuOn:true,
          noMenu:true,
          toolbarHidden:true,
          rotationSliderHidden:true
      }
  }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);

        if(urlParams.get("toolbar")){
            this.setState({toolbarHidden:false})
        }
        if (urlParams.get("slider")) {
            this.setState({rotationSliderHidden:false})
        }

        // window.addEventListener("message", (event:any) => {
        //     if (event.data === "enabletoolbar") {
        //         this.setState({toolbarHidden:false})
        //     }
        // });

        this.state.engine.initEngine().then(()=>{
            this.setState({
                materialGroup:materialGroup,
                backgrounds:backgrounds,
                visibilitySet:visibilitySet,
                renderSetting:renderSettings
            })
            this.state.controller.setMesh(this.state.engine).then(()=>{
                this.state.controller.setLatestFixtureToCurrent()
                this.state.controller.doneLoadingModel()
                this.state.controller.setOption(this.state.engine);
                this.setState({
                    doneLoading:true
                }, ()=>{
                    setTimeout(()=>{this.setState({loading:false})}, 500)
                })
            });
  
        });
    }

    getMenu(){
      if(this.state.menuOn){
          return(
              <Menu toggleMenu={this.toogleMenu.bind(this)} backgrounds={this.state.backgrounds} engine={this.state.engine} materialGroup={this.state.materialGroup} visibilitySet={this.state.visibilitySet} renderSetting={this.state.renderSetting}></Menu>
          )
      }
      else{
          return(
              ""
          )
      }
    }

    toogleMenu(){
      let b = !this.state.menuOn;
      this.setState({
          menuOn: b
      },()=>{this.state.engine.resize()})
    }

  render(){
    //   if(this.state.noMenu){
    //       return(
    //           <div>
    //               {this.state.loading?
    //                   <div className={"loader-holder" + (this.state.doneLoading?" done-loading":"")}>
    //                     <div className="loader"><img src={"/public/loading.gif"}/><span>Gathering the 3D Library</span></div>
    //                   </div>:""}
    //               <div id="scene-canvas" className="scene-canvas" style={{"width":"100%"}}></div>                  
    //           </div>
    //       )
    //   }
    return(
        <div>
            { !this.state.noMenu && this.state.loading?
                      <div className={"loader-holder" + (this.state.doneLoading?" done-loading":"")}>
                        <div className="loader"><img src={"/public/loading.gif"}/><span>Gathering the 3D Library</span></div>
                      </div>:""}
            {!this.state.noMenu ? this.getMenu() : null}
            <div id="scene-canvas" className="scene-canvas" style={{width:!this.state.noMenu && this.state.menuOn? "80%" : '100%'}}></div>
            <div className="arrow" onClick={this.toogleMenu.bind(this)}>
                <i className="fa fa-arrow-circle-right"></i>
            </div>  
            <Toolbar controller={this.state.controller} toolbarHidden={this.state.toolbarHidden} rotationSliderHidden={this.state.rotationSliderHidden}/>        
        </div>
    )
  }
}
